import { startOfMonth, startOfToday } from 'date-fns'

export const AGENDA_TYPES = {
  SET_CURRENT_MONTH: 'setCurrentMonth',
  SET_SELECTED_DAY: 'setSelectedDay',
  SET_MONTH_EVENTS: 'setMonthEvents'
}

const AGENDA_INITIAL_STATE = {
  currentMonth: startOfMonth(new Date()),
  monthEvents: [],
  selectedDay: startOfToday()
}

const agenda = (state = AGENDA_INITIAL_STATE, action) => {
  switch (action.type) {
    case AGENDA_TYPES.SET_CURRENT_MONTH:
      return { ...state, currentMonth: action.currentMonth }
    case AGENDA_TYPES.SET_MONTH_EVENTS:
      return { ...state, monthEvents: action.monthEvents }
    case AGENDA_TYPES.SET_SELECTED_DAY:
      return { ...state, selectedDay: action.selectedDay }

    default:
      return state
  }
}

export default agenda
