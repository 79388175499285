export const searchForm = {
  tipo: '-1',
  versao: '-1',
  cliente: '-1',
  tecnico: '-1'
}

export const sidebarInitialState = {
  search: searchForm
}

export const SIDEBAR_TYPES = {
  SEARCH_FORM: 'SEARCH_FORM'
}

const sidebar = (state = sidebarInitialState, action) => {
  switch (action.type) {
    case SIDEBAR_TYPES.SEARCH_FORM: {
      return {
        ...state,
        search: action.payload
      }
    }

    default:
      return state
  }
}

export default sidebar
