const authInitialState = {
  user: null
}

export const AUTH_TYPES = {
  USER: 'USER'
}

const auth = (state = authInitialState, action) => {
  switch (action.type) {
    case AUTH_TYPES.USER: {
      return {
        ...state,
        user: action.payload
      }
    }

    default:
      return state
  }
}

export default auth
