import './index.scss'

import React, { Suspense } from 'react'
import { Provider } from 'react-redux'
import { BrowserRouter, Route, Switch } from 'react-router-dom'
import { ToastContainer } from 'react-toastify'

import store from './redux/store'
import LoadingPage from './components/LoadingPage'

const Changelog = React.lazy(() => import('./scenes/Changelog'))
const Principal = React.lazy(() => import('./layouts/Principal'))

function App () {
  return (
    <Suspense fallback={<LoadingPage message="Carregando site" />}>
      <Provider store={store}>
        <ToastContainer />
        <BrowserRouter>
          <Switch>
            <Route path="/changelog" component={Changelog} />
            <Route path="/" component={Principal} />
          </Switch>
        </BrowserRouter>
      </Provider>
    </Suspense>
  )
}

export default App
