import { TDataResult } from 'jollaz-api-queries'

export const CHANGELOG_TYPES = {
  FETCH_CHANGELOGS: 'FETCH_CHANGELOG'
}

const changelogInitialState = {
  changelogResult: new TDataResult()
}

const changelog = (state = changelogInitialState, action) => {
  switch (action.type) {
    case CHANGELOG_TYPES.FETCH_CHANGELOGS: {
      return {
        ...state,
        changelogResult: action.payload
      }
    }

    default:
      return state
  }
}

export default changelog
